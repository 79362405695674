import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

const applyLetterOnlyClass = () => {
  try {
    const elements = document.querySelectorAll('*:not(script):not(style):not(meta):not(link)'); // Select all elements except script, style, meta, and link

    elements.forEach(element => {
      element.childNodes.forEach(child => {
        if (child.nodeType === Node.TEXT_NODE) {
          const newHtml = child.nodeValue?.replace(/([A-Za-z])/g, '<span class="letter-only">$1</span>');
          if (newHtml && child.parentNode) {
            const fragment = document.createDocumentFragment();
            const tempElement = document.createElement('div');
            tempElement.innerHTML = newHtml;
            Array.from(tempElement.childNodes).forEach(node => fragment.appendChild(node));
            child.parentNode.replaceChild(fragment, child);
          }
        }
      });
    });
  } catch (error) {
    console.error("Error applying letter-only class: ", error);
  }
};

// Run the function after the DOM is fully loaded
document.addEventListener('DOMContentLoaded', applyLetterOnlyClass);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
