import React, { useEffect, useState, useRef } from 'react';
import nwLogo from '../../assets/images/nw-logo.png';
import '../../styles/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

interface HeaderProps {
  onServiceClick: (serviceName: string) => void;
}

const Header: React.FC<HeaderProps> = ({ onServiceClick }) => {
  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && !(event.target as HTMLElement).closest('.menu-toggle-button')) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, targetId: string) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const offset = 80; // Adjust this value to offset the scroll position
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      closeMenu();
    }
  };

  return (
    <header className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${scrolling ? 'bg-white bg-opacity-70 shadow-md' : 'bg-white'}`}>
      <div className="container mx-auto px-4 md:px-8 py-6 md:py-8 flex flex-col md:flex-row justify-between items-center">
        <img src={nwLogo} alt="NW Logo" className="w-80 md:w-96 h-auto" />
        <button
          onClick={toggleMenu}
          className="menu-toggle-button md:hidden text-cyan-900 focus:outline-none mt-4 md:mt-0"
          aria-label="Toggle menu"
          aria-expanded={menuOpen}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <nav ref={menuRef} className={`w-full md:w-auto ${menuOpen ? 'bg-transparent border-t-2 border-cyan-900 shadow-lg mt-2' : 'md:bg-transparent md:border-none md:shadow-none'} transition-all duration-300 ${scrolling ? 'bg-opacity-70' : ''}`}>
          <ul className={`md:flex md:space-x-10 text-center flex-col md:flex-row ${menuOpen ? 'flex' : 'hidden'} md:flex text-cyan-900 font-clarika-pro-geo-md text-lg md:text-xl ${menuOpen ? 'responsive-menu' : ''}`}>
            <li className="py-3 md:py-0">
              <a href="#about" className="header-link" onClick={(e) => handleLinkClick(e, 'about')}>ABOUT</a>
            </li>
            <li className="py-3 md:py-0">
              <a href="#services" className="header-link" onClick={(e) => handleLinkClick(e, 'services')}>SERVICES</a>
            </li>
            <li className="py-3 md:py-0">
              <a href="#clients" className="header-link" onClick={(e) => handleLinkClick(e, 'clients')}>CLIENTS</a>
            </li>
            <li className="py-3 md:py-0">
              <a href="#contact" className="header-link" onClick={(e) => handleLinkClick(e, 'contact')}>CONTACT</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
