import React from 'react';

const AboutUs: React.FC = () => {
  return (
    <section id="about" className="bg-gray-100 py-10 md:py-20">
      <div className="container mx-auto px-4 md:px-6">
        <p className="text-base md:text-lg lg:text-xl leading-relaxed text-justify text-cyan-900 font-clarika-pro-geo-lt">
          Founded in 2020 by Eduardo Artigas and Michael Martin, partners that have over 35 years in the janitorial industry,
          Nationwide Building Solutions is already a leading name in janitorial and facilities maintenance in Florida. Our diverse client 
          base spans industries like healthcare, automotive, hospitality, and more. We strive for excellence, acknowledging that 
          perfection is a journey and should any issues arise, we are committed to prompt resolution, ensuring client satisfaction 
          remains our top priority.
        </p>
      </div>
    </section>
  );
}

export default AboutUs;
