import React from 'react';
import Slider from 'react-slick';
import logo1 from '../../assets/images/clientsimgs/AsburyAutomotiveGroupLogo.png';
import logo2 from '../../assets/images/clientsimgs/BrandonHondaLogo.png';
import logo3 from '../../assets/images/clientsimgs/BullaGastrobarLogo.png';
import logo4 from '../../assets/images/clientsimgs/ChromalloyLogo.png';
import logo5 from '../../assets/images/clientsimgs/CourtesyToyotaOfBrandonLogo.png';
import logo6 from '../../assets/images/clientsimgs/ElderFordOfTampaLogo.png';
import logo7 from '../../assets/images/clientsimgs/HealthcareRealtyLogo.png';
import logo8 from '../../assets/images/clientsimgs/HolladayPropertiesLogo.png';
import logo9 from '../../assets/images/clientsimgs/JaguarOfTampaLogo.png';
import logo10 from '../../assets/images/clientsimgs/MeatMarketLogo.png';
import logo11 from '../../assets/images/clientsimgs/MercedesBenzOfTampaLogo.png';
import logo12 from '../../assets/images/clientsimgs/QuestDiagnosticsLogo.png';
import logo13 from '../../assets/images/clientsimgs/RepublicBankLogo.png';
import logo14 from '../../assets/images/clientsimgs/RizzettaRealEstateLogo.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../styles/styles.css';

const Clients: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const logos = [
    { src: logo1, enlarge: true },
    { src: logo2, enlarge: false },
    { src: logo3, enlarge: true },
    { src: logo4, enlarge: true },
    { src: logo5, enlarge: false },
    { src: logo6, enlarge: false },
    { src: logo7, enlarge: true },
    { src: logo8, enlarge: true },
    { src: logo9, enlarge: true },
    { src: logo10, enlarge: true },
    { src: logo11, enlarge: true },
    { src: logo12, enlarge: true },
    { src: logo13, enlarge: false },
    { src: logo14, enlarge: false }
  ];

  return (
    <section id="clients" className="bg-white py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-clarika-pro-geo-bd text-center mb-12 text-cyan-900">Clients</h2>
        <Slider {...settings}>
          {logos.map((logo, index) => (
            <div key={index} className="p-4">
              <img
                src={logo.src}
                alt={`Client Logo ${index + 1}`}
                className={`mx-auto ${logo.enlarge ? 'h-44 md:h-48 logo-enlarge' : 'h-16 md:h-24'} object-contain`}
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Clients;
