import React from 'react';
import logo from '../../assets/images/nw-logo-sv.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer: React.FC = () => {
  return (
    <footer id="contact" className="bg-gray-200 py-6 md:py-8"> {/* Diminuí o padding vertical */}
      <div className="container mx-auto px-4 md:px-8 flex flex-col md:flex-row justify-between items-center">
        <div className="text-center md:text-left mx-2 md:mx-4 my-2 md:my-0"> {/* Ajustei as margens */}
          <p className="text-cyan-900 font-clarika-pro-geo-bd font-bold text-base md:text-lg lg:text-xl"> {/* Ajustei os tamanhos das fontes */}
            CONTACT INFO
          </p>
          <div className="mt-2">
            <p className="text-xs md:text-sm lg:text-base font-clarika-pro-geo-rg my-1"> {/* Ajustei os tamanhos das fontes */}
              <FontAwesomeIcon icon={faLocationDot} className="mr-2" />
              3330 West Cypress St, Tampa, FL 33607
            </p>
            <p className="text-xs md:text-sm lg:text-base font-clarika-pro-geo-rg my-1"> {/* Ajustei os tamanhos das fontes */}
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              <a href="mailto:info@nbs.team">Info<span className="special-char">@</span>nbs.team</a>
            </p>
            <p className="text-xs md:text-sm lg:text-base my-1"> {/* Ajustei os tamanhos das fontes */}
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              1-888-294-1961
            </p>
          </div>
        </div>
        <div className="text-center my-2 md:my-0"> {/* Ajustei as margens */}
          <img src={logo} alt="Logo" className="w-20 md:w-24 h-auto" /> {/* Ajustei o tamanho da imagem */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
