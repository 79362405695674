import React from 'react';
import '../../styles/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faUsers, faBullseye } from '@fortawesome/free-solid-svg-icons';

const MissionAndValues = () => {
  return (
    <section id="mission-values" className="bg-white-100 py-20">
      <div className="mission-values-container">
        <h2 className="mission-values-heading">Missions and Values</h2>
        <div className="mission-values-flex">
          <div className="mission-values-card">
            <div className="text-center">
              <FontAwesomeIcon icon={faAward} className="mission-values-icon" />
            </div>
            <h3 className="mission-values-title">Commitment to Excellence</h3>
            <p className="mission-values-text">
              At Nationwide Building Solutions, we deliver exceptional janitorial and facilities maintenance services, striving for superior cleanliness and safety.
            </p>
          </div>
          <div className="mission-values-card">
            <div className="text-center">
              <FontAwesomeIcon icon={faUsers} className="mission-values-icon" />
            </div>
            <h3 className="mission-values-title">Customer-Centric Approach</h3>
            <p className="mission-values-text">
              We prioritize client needs, fostering long-term partnerships through trust, reliability, and quality service delivery.
            </p>
          </div>
          <div className="mission-values-card">
            <div className="text-center">
              <FontAwesomeIcon icon={faBullseye} className="mission-values-icon" />
            </div>
            <h3 className="mission-values-title">Problem-Solving</h3>
            <p className="mission-values-text">
              Nationwide Building Solutions tackles janitorial and maintenance challenges head-on, providing reliable solutions for cleaner, safer, and more efficient environments.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MissionAndValues;
