import React, { useState, useEffect } from 'react';
import cleaningVideo from '../../assets/videos/cleaning-video.mp4';
import usFlag from '../../assets/images/usflagw.png';

const BackgroundVideo: React.FC = () => {
  const [showSloganPart1, setShowSloganPart1] = useState(true);
  const [showSloganPart2, setShowSloganPart2] = useState(false);
  const [colors, setColors] = useState({ cleanliness: '', excellence: '', time: '', money: '', yourself: '' });
  const [loop, setLoop] = useState(0);

  useEffect(() => {
    const timeouts = [
      setTimeout(() => setColors((prev) => ({ ...prev, cleanliness: '#2f5194' })), 1500),
      setTimeout(() => setColors((prev) => ({ ...prev, excellence: '#e22328' })), 2500),
      setTimeout(() => {
        setColors({ cleanliness: '', excellence: '', time: '', money: '', yourself: '' });
        setShowSloganPart2(true);
      }, 5000),
      setTimeout(() => setColors((prev) => ({ ...prev, time: '#ffff00' })), 6000),
      setTimeout(() => setColors((prev) => ({ ...prev, money: '#ffff00' })), 6500),
      setTimeout(() => setColors((prev) => ({ ...prev, yourself: '#ffff00' })), 7000),
      setTimeout(() => {
        setShowSloganPart1(true);
        setShowSloganPart2(false);
        setLoop((prev) => prev + 1);
      }, 9000),
    ];

    return () => timeouts.forEach(clearTimeout);
  }, [loop]);

  const handleLetterHover = (event: React.MouseEvent<HTMLSpanElement>) => {
    const target = event.target as HTMLSpanElement;
    if (target.textContent) {
      const index = Array.prototype.indexOf.call(target.parentNode?.childNodes, target);
      target.style.color = index % 2 === 0 ? '#e22328' : '#2f5194';
      target.style.cursor = 'default';
    }
  };

  const handleLetterLeave = (event: React.MouseEvent<HTMLSpanElement>) => {
    const target = event.target as HTMLSpanElement;
    target.style.color = '';
  };

  return (
    <div className="relative w-full h-screen">
      <video autoPlay muted loop playsInline className="absolute inset-0 object-cover w-full h-full z-0">
        <source src={cleaningVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showSloganPart1 && (
        <div className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 text-white z-10 text-center w-full">
          <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold font-clarika-pro-geo-bd">
            <span>Where </span>
            <span style={{ color: colors.cleanliness }}>Cleanliness </span>
            <span>Meets </span>
            <span style={{ color: colors.excellence }}>Excellence </span>
            <span>.</span>
          </h1>
        </div>
      )}
      {showSloganPart2 && (
        <div className="absolute inset-x-0 top-1/2 transform translate-y-1/2 text-white z-10 text-center w-full mt-4">
          <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold font-clarika-pro-geo-bd">
            <span className="font-sans">-</span>
            <span> Save </span>
            <span style={{ color: colors.time }}>Time</span>
            <span>. </span>
            <span>Save </span>
            <span style={{ color: colors.money }}>Money</span>
            <span>. </span>
            <span>Save </span>
            <span style={{ color: colors.yourself }}>Yourself </span>
            <span className="font-sans">-</span>
          </h2>
        </div>
      )}
      <div className="absolute inset-x-0 bottom-8 flex flex-row justify-center items-center text-white z-10">
        <h1 className="text-lg md:text-xl lg:text-2xl font-bold font-clarika-pro-geo-bd flex flex-row items-center">
          {Array.from('"Make America Clean Again"').map((char, index) =>
            char === '"' ? (
              <img key={index} src={usFlag} alt="US Flag" className="w-6 h-4 mx-1 flag-image" />
            ) : (
              <span
                key={index}
                className="hover-effect"
                onMouseEnter={handleLetterHover}
                onMouseLeave={handleLetterLeave}
              >
                {char}
              </span>
            )
          )}
        </h1>
      </div>
    </div>
  );
};

export default BackgroundVideo;
