import { useState, useEffect } from 'react';

const useDynamicBottom = (offset = 10, sideMargin = '1rem', isOpen = false) => {
  const [bottom, setBottom] = useState('2rem');

  const updateBottom = () => {
    if (isOpen) {
      setBottom('2rem');  // Fix the bottom position when the form is open
      return;
    }

    const footer = document.querySelector('footer');
    if (footer) {
      const footerRect = footer.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const footerTop = footerRect.top;

      if (footerTop <= windowHeight) {
        setBottom(`${windowHeight - footerTop + offset}px`);
      } else {
        setBottom('2rem');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', updateBottom);
    window.addEventListener('resize', updateBottom); // Ensure style updates on window resize
    updateBottom(); // Initial check on mount

    return () => {
      window.removeEventListener('scroll', updateBottom);
      window.removeEventListener('resize', updateBottom);
    };
  }, [offset, sideMargin, isOpen]);

  return { bottom, marginLeft: sideMargin, marginRight: sideMargin };
};

export default useDynamicBottom;
