import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

interface CollapsibleProps {
  title: string;
  children: ReactNode;
  isOpen?: boolean;
  onToggle?: () => void;
}

const Collapsible: React.FC<CollapsibleProps> = ({ title, children, isOpen = false, onToggle }) => {
  return (
    <div className="collapsible mb-4">
      <button
        onClick={onToggle}
        className="w-full text-left text-lg font-clarika-pro-geo-lt text-cyan-900 bg-gray-100 hover:bg-gray-200 p-2 rounded-md transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-cyan-900 flex justify-between items-center"
        aria-expanded={isOpen}
      >
        <span>{title}</span>
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
      </button>
      {isOpen && (
        <div className="mt-2 p-2 bg-gray-50 rounded-md border-l-4 border-cyan-900 text-justify">
          <div className="font-sans">{children}</div>
        </div>
      )}
    </div>
  );
};

export default Collapsible;
