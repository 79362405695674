import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import useDynamicBottom from '../../../hooks/useDynamicBottom';
import '../../../styles/styles.css';

const ContactForm: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    help: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    comments: '',
  });
  const [errors, setErrors] = useState({
    help: false,
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    comments: false,
  });

  const { bottom } = useDynamicBottom(10, '1rem', isOpen);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const resetForm = () => {
    setFormData({
      help: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      comments: '',
    });
    setErrors({
      help: false,
      firstName: false,
      lastName: false,
      phone: false,
      email: false,
      comments: false,
    });
  };

  const toggleForm = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      resetForm();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: false,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newErrors = {
      help: !formData.help,
      firstName: !formData.firstName,
      lastName: !formData.lastName,
      phone: !formData.phone,
      email: !formData.email,
      comments: !formData.comments,
    };

    setErrors(newErrors);
    const hasErrors = Object.values(newErrors).some(error => error);
    if (hasErrors) {
      return;
    }

    try {
      const response = await axios.post('https://nbs-backend.vercel.app/api/send-email', formData);
      console.log('Email sent:', response.data);
      alert('Your message has been sent successfully!');
    } catch (error) {
      console.error('Email send error:', error);
      alert('There was an error sending your message. Please try again.');
    }

    resetForm();
    setIsOpen(false);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (formRef.current && !formRef.current.contains(e.target as Node)) {
      setIsOpen(false);
      resetForm();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div style={{ position: 'fixed', bottom, left: '1rem', zIndex: 50 }}>
      <button className="button-common fixed-button" onClick={toggleForm}>
        <span className="text-xs sm:text-sm">Talk to Us</span>
        <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronUp} className="ml-2" />
      </button>
      {isOpen && (
        <form ref={formRef} className="form-common w-full sm:w-auto" onSubmit={handleSubmit}>
          <h2 className="text-lg font-bold mb-2 sm:mb-4">How can we help?</h2>
          <div className="mb-2 sm:mb-3">
            <select
              name="help"
              value={formData.help}
              onChange={handleChange}
              className="input-common"
            >
              <option value="">Select an option...</option>
              <option value="I need sales assistance">I need sales assistance</option>
              <option value="I am currently a client and need assistance">I am currently a client and need assistance</option>
              <option value="I am currently an employee">I am currently an employee</option>
              <option value="I would like to become an employee">I would like to become an employee</option>
            </select>
            {errors.help && <p className="error-text">This field is required.</p>}
          </div>

          <div className="mb-2 sm:mb-3">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              className="input-common"
            />
            {errors.firstName && <p className="error-text">This field is required.</p>}
          </div>

          <div className="mb-2 sm:mb-3">
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              className="input-common"
            />
            {errors.lastName && <p className="error-text">This field is required.</p>}
          </div>

          <div className="mb-2 sm:mb-3">
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone Number"
              className="input-common"
            />
            {errors.phone && <p className="error-text">This field is required.</p>}
          </div>

          <div className="mb-2 sm:mb-3">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address"
              className="input-common"
            />
            {errors.email && <p className="error-text">This field is required.</p>}
          </div>

          <div className="mb-2 sm:mb-3">
            <textarea
              name="comments"
              value={formData.comments}
              onChange={handleChange}
              placeholder="Comments"
              className="input-common h-20"
            ></textarea>
            {errors.comments && <p className="error-text">This field is required.</p>}
          </div>

          <button type="submit" className="button-common">
            Send
          </button>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
