import React, { useState } from 'react';
import Header from './components/Header/Header';
import BackgroundVideo from './components/Main/BackgroundVideo';
import AboutUs from './components/Main/AboutUs';
import MissionsAndValues from './components/Main/MissionsAndValues';
import Services from './components/Main/Services/Services';
import Clients from './components/Main/Clients';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/Main/Buttons/ScrollToTop';
import ContactForm from './components/Main/Buttons/ContactForm';

const App: React.FC = () => {
  const [selectedService, setSelectedService] = useState<null | string>(null);

  const handleServiceClick = (serviceName: string) => {
    setSelectedService(serviceName);
  };

  return (
    <div id="top">
      <Header onServiceClick={handleServiceClick} />
      <BackgroundVideo />
      <AboutUs />
      <MissionsAndValues />
      <Services selectedService={selectedService} />
      <Clients />
      <ContactForm />
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default App;
