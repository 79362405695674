import React, { useState, useEffect, useCallback } from 'react';
import '../../../styles/styles.css';
import janitorial from '../../../assets/images/servicesimgs/Janitorial.png';
import maintenence from '../../../assets/images/servicesimgs/Maintenence.png';
import exteriorservices from '../../../assets/images/servicesimgs/ExteriorServices.png';
import janitorialImg from '../../../assets/images/servicesimgs/janitorial-services-img.webp';
import maintenanceImg from '../../../assets/images/servicesimgs/maintenance-services-img.jpg';
import exteriorImg from '../../../assets/images/servicesimgs/exterior-services-img.jpeg';
import Collapsible from './Collapsible';

const services = [
  {
    name: 'Janitorial',
    defaultImage: janitorial,
    hoverImage: janitorialImg,
    modalContent: (
      <div className="modal-content">
        <Collapsible title="Commercial and Industrial Cleaning">
          Tailored services to meet sector-specific needs, ensuring superior cleanliness and safety.
        </Collapsible>
        <Collapsible title="Construction Clean">
          Thorough cleansing for post-construction spaces, meeting the highest standards of cleanliness and hygiene.
        </Collapsible>
        <Collapsible title="Floor Care and Restoration">
          Pioneer in floor care, restoring floors to their original luster and enhancing the overall appearance.
        </Collapsible>
        <Collapsible title="Porter and Matron Services">
          Continuous cleanliness and maintenance to uphold a pristine environment for employees and visitors alike.
        </Collapsible>
      </div>
    ),
  },
  {
    name: 'Maintenance',
    defaultImage: maintenence,
    hoverImage: maintenanceImg,
    modalContent: (
      <div className="modal-content">
        <Collapsible title="Facilities Maintenance">
          Results-driven approach to maintaining facilities, ensuring optimal performance and longevity.
        </Collapsible>
        <Collapsible title="Carpentry">
          Precision and skill define our carpentry services, offering customized solutions for all your woodworking needs.
        </Collapsible>
        <Collapsible title="Electrical">
          Reliable and safe electrical services to keep your systems running smoothly and efficiently.
        </Collapsible>
        <Collapsible title="HVAC">
          Optimal climate control solutions to maintain comfortable indoor environments year-round.
        </Collapsible>
        <Collapsible title="Locksmith">
          Secure and efficient locksmith services for all your security needs.
        </Collapsible>
        <Collapsible title="Masonry">
          Enhance the look and strength of your facilities with our top-notch masonry services.
        </Collapsible>
        <Collapsible title="Painting">
          Exceptional painting services to transform the appearance of your facilities.
        </Collapsible>
        <Collapsible title="Plumbing">
          Functional and reliable plumbing services to address all your plumbing needs.
        </Collapsible>
      </div>
    ),
  },
  {
    name: 'Exterior Services',
    defaultImage: exteriorservices,
    hoverImage: exteriorImg,
    modalContent: (
      <div className="modal-content">
        <Collapsible title="Mulching and Planting">
          Beyond the basics, our services enhance the beauty and sustainability of outdoor spaces.
        </Collapsible>
        <Collapsible title="Landscaping and Tree Trimming">
          Create visually stunning landscapes and maintain healthy trees for a picturesque environment.
        </Collapsible>
        <Collapsible title="Paved Surfaces, Fences, and Trash">
          Meticulous care for hardscapes, ensuring they remain clean, safe, and visually appealing.
        </Collapsible>
        <Collapsible title="Pressure Washing">
          Restore the look of your property's exterior surfaces, enhancing curb appeal and longevity.
        </Collapsible>
      </div>
    ),
  },
];

interface ServicesProps {
  selectedService: string | null;
}

const Services: React.FC<ServicesProps> = ({ selectedService }) => {
  const [currentService, setCurrentService] = useState<null | typeof services[0]>(null);
  const [hoveredService, setHoveredService] = useState<string | null>(null);
  const [activeCollapsible, setActiveCollapsible] = useState<string | null>(null);

  useEffect(() => {
    if (selectedService) {
      const service = services.find(service => service.name === selectedService);
      if (service) {
        setCurrentService(service);
      }
    } else {
      setCurrentService(null);
    }
  }, [selectedService]);

  useEffect(() => {
    if (currentService) {
      document.body.style.overflow = 'hidden';
      setHoveredService(null); // Reset hovered service when modal is opened
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [currentService]);

  const closeModal = () => {
    setCurrentService(null);
    setActiveCollapsible(null);
  };

  const handleToggleCollapsible = useCallback((title: string) => {
    setActiveCollapsible(prev => (prev === title ? null : title));
  }, []);

  const handleServiceClick = (service: typeof services[0]) => {
    setCurrentService(service);
    setHoveredService(null); // Reset hovered service on click
  };
  return (
    <section id="services" className="bg-gray-100 py-20">
      <div>
        <h2 className="text-3xl md:text-4xl font-clarika-pro-geo-bd text-center mb-12 text-cyan-900">Services</h2>
        <div className={`flex flex-wrap justify-center gap-4 md:gap-6 lg:gap-8 ${currentService ? 'pointer-events-none' : 'pointer-events-auto'}`}>
          {services.map((service, index) => (
            <div
              key={index}
              className={`relative group service-card w-full md:w-1/3 lg:w-1/4 cursor-pointer ${currentService ? 'modal-open' : ''}`}
              onClick={() => handleServiceClick(service)}
              onMouseEnter={() => setHoveredService(service.name)}
              onMouseLeave={() => setHoveredService(null)}
            >
              <div
                className="flex items-center justify-center p-4 h-40 md:h-48 lg:h-56 bg-white rounded-lg shadow-lg transition-transform duration-300 transform hover:scale-105"
                style={{
                  backgroundImage: `url(${hoveredService === service.name ? service.hoverImage : service.defaultImage})`,
                  backgroundSize: hoveredService === service.name ? 'cover' : '25% auto',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              ></div>
              <div className={`absolute inset-0 bg-cyan-900 bg-opacity-75 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg ${currentService ? 'hidden' : ''}`}>
                <h3 className="text-white text-base md:text-xl lg:text-2xl font-bold text-center service-title">{service.name}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
      {currentService && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={closeModal}>
          <div className="relative w-10/12 sm:w-8/12 md:w-2/3 lg:w-1/3 max-h-screen p-4 sm:p-6" onClick={(e) => e.stopPropagation()}>
            <div
              className="modal-bg border-2 border-cyan-900"
              style={{
                backgroundImage: `url(${currentService.hoverImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '0', // Border radius set to 0 to remove rounded corners
              }}
            ></div>
            <div className="relative bg-white p-4 sm:p-6 rounded-lg shadow-lg bg-opacity-75">
              <button className="absolute top-0 right-0 mt-4 mr-3 text-xl text-gray-500 hover:text-red-500 transform -translate-y-1/2" onClick={closeModal}>&times;</button>
              <h3 className="text-2xl font-bold mb-4 text-center text-cyan-900">{currentService.name}</h3>
              <div className="text-xs sm:text-sm service-text">
                {React.Children.map(currentService.modalContent.props.children, (child) =>
                  React.cloneElement(child, {
                    isOpen: activeCollapsible === child.props.title,
                    onToggle: () => handleToggleCollapsible(child.props.title),
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Services;
